import { Environment } from '../enums'

const env = {
  // API_URL: 'http://localhost:8082/api',
  API_URL: 'https://api.alltheapps.io/api',

  GHL: {
    // CLIENT_ID: '64f8604282165264339a0ccc-lm7nf7n0',
    // CLIENT_SECRET: '84249575-8af0-44bf-adb0-d8cf81d6cecb',

    CLIENT_ID: '668fce5baff239cd7461ffb7-lyh97gk6',
    CLIENT_SECRET: '6cff26fc-9308-4b33-9147-d945766ab67a',
    Location_CLIENT_ID: '668fce5baff239cd7461ffb7-lyh97gk6',
    Location_CLIENT_SECRET: '6cff26fc-9308-4b33-9147-d945766ab67a',
    Location_SCOPE:
      'conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.write contacts.readonly',

    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    REDIRECT: {
      ASSOCIATE: 'http://localhost:3000/integrations/associate',
      AGENCY: 'https://superadmin.levelupmarketplace.com/integrations/associate/agency',
    },
  },
}

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = 'https://api.alltheapps.io/api'
  env.GHL.REDIRECT.ASSOCIATE = 'https://superadmin.levelupmarketplace.com/integrations/associate'
}
if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://api.alltheapps.io/api'
  env.GHL.REDIRECT.ASSOCIATE = 'https://superadmin.levelupmarketplace.com/integrations/associate'
}
export default env
