/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/

// @mui material components
import { Card, Tab, Tabs } from '@mui/material'
import Grid from '@mui/material/Grid'

// MD UI Dashboard PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// MD UI Dashboard PRO React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import AppData from './components/data'
import ImageCreateModal from './components/Modals/ImageModal'
import Widget from './components/Widget'
import Snapshot from './components/Snapshot'
import PaymentProvider from './components/PaymentProvider'
import CustomJS from './components/CustomJS'
import AppDescription from './components/AppDescription'
import ConversationProviderModal from './components/Modals/ConversationProviderModal'
import VideoCreateModal from './components/Modals/VideoModal'
import TriggerCreateModal from './components/Modals/TriggerModal'
import ActionCreateModal from './components/Modals/ActionModal'
import DocCreateModal from './components/Modals/DocsModal'
import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import { useAgencyInfo } from 'context/agency'
import Loader from 'examples/Loader'
function Default() {
  const {
    loader,
    ImageDataTableData,
    VideoDataTableData,
    handleRefresh,
    appData,
    TriggerDataTableData,
    ActionDataTableData,
    DocDataTableData,
    ConversationprovidersDataTableData,
  } = AppData()
  const [tabIndex, setTabIndex] = useState(0)
  const [app_features, setApp_features] = useState(appData?.app_features)
  const onLoad = () => {
    setApp_features(appData?.app_features)
  }

  useEffect(onLoad, [appData])
  return (
    <DashboardLayout>
      {loader ? (
        <Loader />
      ) : (
        <>
          <DashboardNavbar />
          <Card pt={6} pb={3}>
            <MDBox sx={{ width: '100%' }} p={2}>
              <MDBox mb={3}>
                <Tabs
                  value={tabIndex}
                  onChange={(e, index) => setTabIndex(index)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Profiles" />
                  <Tab label="Features" />
                </Tabs>
              </MDBox>
              {tabIndex == 0 && (
                <Grid container spacing={6}>
                  {appData?.app_type?.value == 'both' || appData?.app_type?.value == 'agency' ? (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center  "
                        >
                          <MDTypography variant="h6" color="white">
                            App Description For Agency
                          </MDTypography>
                        </MDBox>
                        <MDBox pt={3} px={3}>
                          <AppDescription
                            handleRefresh={handleRefresh}
                            appData={appData}
                            type="agency"
                            description={appData?.agency_description}
                          />
                        </MDBox>
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {appData?.app_type?.value == 'both' ||
                  appData?.app_type?.value == 'sub_account' ? (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center  "
                        >
                          <MDTypography variant="h6" color="white">
                            App Description For Location
                          </MDTypography>
                        </MDBox>
                        <MDBox pt={3} px={3}>
                          <AppDescription
                            handleRefresh={handleRefresh}
                            appData={appData}
                            type="location"
                            description={appData?.loc_description}
                          />
                        </MDBox>
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center  "
                      >
                        <MDTypography variant="h6" color="white">
                          App Images
                        </MDTypography>
                        <ImageCreateModal handleRefresh={handleRefresh} appData={appData} />
                      </MDBox>
                      <MDBox pt={3} px={3}>
                        <DataTable table={ImageDataTableData} canSearch entriesPerPage={5} />
                      </MDBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center  "
                      >
                        <MDTypography variant="h6" color="white">
                          App Videos
                        </MDTypography>
                        <VideoCreateModal handleRefresh={handleRefresh} appData={appData} />
                      </MDBox>
                      <MDBox pt={3} px={3}>
                        <DataTable table={VideoDataTableData} canSearch entriesPerPage={5} />
                      </MDBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center  "
                      >
                        <MDTypography variant="h6" color="white">
                          App Docs
                        </MDTypography>
                        <DocCreateModal handleRefresh={handleRefresh} appData={appData} />
                      </MDBox>
                      <MDBox pt={3} px={3}>
                        <DataTable table={DocDataTableData} canSearch entriesPerPage={5} />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              )}
              {tabIndex == 1 && (
                <Grid container spacing={6}>
                  {app_features?.find((item) => item.value == 'triggers') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center  "
                        >
                          <MDTypography variant="h6" color="white">
                            App Triggers
                          </MDTypography>
                          <TriggerCreateModal handleRefresh={handleRefresh} appData={appData} />
                        </MDBox>
                        <MDBox pt={3} px={3}>
                          <DataTable table={TriggerDataTableData} canSearch entriesPerPage={5} />
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'actions') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center  "
                        >
                          <MDTypography variant="h6" color="white">
                            App Actions
                          </MDTypography>
                          <ActionCreateModal handleRefresh={handleRefresh} appData={appData} />
                        </MDBox>
                        <MDBox pt={3} px={3}>
                          <DataTable table={ActionDataTableData} canSearch entriesPerPage={5} />
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'conversation_providers') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center  "
                        >
                          <MDTypography variant="h6" color="white">
                            Conversation Providers
                          </MDTypography>
                          <ConversationProviderModal
                            handleRefresh={handleRefresh}
                            appData={appData}
                          />
                        </MDBox>
                        <MDBox pt={3} px={3}>
                          <DataTable
                            table={ConversationprovidersDataTableData}
                            canSearch
                            entriesPerPage={5}
                          />
                        </MDBox>
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'widget') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="h6" color="white">
                            Widget
                          </MDTypography>
                        </MDBox>
                        <Widget handleRefresh={handleRefresh} appData={appData} />
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'snapshot') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="h6" color="white">
                            Snapshot
                          </MDTypography>
                        </MDBox>
                        <Snapshot handleRefresh={handleRefresh} appData={appData} />
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'payment_provider') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="h6" color="white">
                            Payment Provider
                          </MDTypography>
                        </MDBox>
                        <PaymentProvider handleRefresh={handleRefresh} appData={appData} />
                      </Card>
                    </Grid>
                  )}
                  {app_features?.find((item) => item.value == 'custom_js') && (
                    <Grid item xs={12}>
                      <Card>
                        <MDBox
                          mx={2}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="h6" color="white">
                            Custom JS
                          </MDTypography>
                        </MDBox>
                        <CustomJS handleRefresh={handleRefresh} appData={appData} />
                      </Card>
                    </Grid>
                  )}
                  {/* <Grid item xs={12}>
                     <Card>
                       <MDBox
                         mx={2}
                         py={3}
                         px={2}
                         variant="gradient"
                         bgColor="info"
                         borderRadius="lg"
                         coloredShadow="info"
                         display="flex"
                         justifyContent="space-between"
                         alignItems="center  "
                       >
                         <MDTypography variant="h6" color="white">
                           App Events
                         </MDTypography>
                         <EventCreateModal
                           handleRefresh={handleRefresh}
                           appData={appData}
                           events={events}
                         />
                       </MDBox>
                       <MDBox pt={3} px={3}>
                         <DataTable table={EventDataTableData} canSearch entriesPerPage={5} />
                       </MDBox>
                     </Card>
                   </Grid> */}
                </Grid>
              )}
            </MDBox>
          </Card>
        </>
      )}
    </DashboardLayout>
  )
}

export default Default
